<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="900px">
      <v-card v-if="showDialog">
        <v-card-title>
          <span class="headline"
            >{{ category.name }} - {{ question.points }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="quiz-title"> {{ question.title }} </v-row>

            <v-row>
              <v-col cols="3">
                <v-switch
                  v-model="quiz.activeQuestion.showText"
                  label="Frage anzeigen"
                  @change="() => this.updateGame()"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="quiz.activeQuestion.allowAnswers"
                  label="Antworten erlauben"
                  @change="() => this.updateGame()"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="quiz.activeQuestion.showAnswers"
                  label="Antworten anzeigen"
                  @change="() => this.updateGame()"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="quiz.activeQuestion.showWinningTeam"
                  label="Gewinner anzeigen"
                  @change="() => this.updateGame()"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"><h2>Gewinner setzen:</h2></v-col>
              <v-col cols="12 d-flex justify-space-between">
                <v-btn color="default" large @click="setWinner('UNANSWERED')"
                  >Kein Gewinner</v-btn
                >

                <v-btn color="default" large @click="setWinner(null)"
                  >Falsch beantwortet</v-btn
                >

                <v-btn
                  :color="team.color"
                  v-for="team in quiz.teams"
                  :key="team.teamId"
                  large
                  @click="setWinner(team.teamId)"
                  >{{ team.name }}</v-btn
                >
              </v-col>
            </v-row>
            <template v-if="question.questionType == 'geo'">
              <GeoQuestionResults v-bind:quiz="quiz" v-bind:question="question"/>
            </template>
            <template v-if="question.questionType == 'guess'">
              <GuessQuestionResults v-bind:quiz="quiz" v-bind:question="question"/>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "../Firebase";
import GeoQuestionResults from "../components/ActiveQuestion/GeoQuestionResults";
import GuessQuestionResults from "../components/ActiveQuestion/GuessQuestionResults";

export default {
  name: "ActiveQuestion",
  props: ["questionId", "quiz"],
  components: { GeoQuestionResults,GuessQuestionResults },
  data() {
    return {
      ref: firebase.firestore(),
    };
  },
  methods: {
    updateGame() {
      this.ref
        .collection("quizes/")
        .doc(this.quiz.id)
        .set(this.quiz);
    },
    close() {
      this.quiz.activeQuestion = null;
      this.updateGame();
    },
    setWinner(teamId) {
      if (teamId === "UNANSWERED") {
        delete this.question.winnerTeamId;
      } else {
        this.question.winnerTeamId = teamId;
      }
      this.updateGame();
    },
    playerWithId(playerId) {
      return this.quiz.players.find((x) => x.playerId == playerId);
    },
    teamWithId(teamId) {
      return this.quiz.teams.find((x) => x.teamId == teamId);
    },
  },
  computed: {
    showDialog() {
      return this.quiz.activeQuestion != null;
    },
    question() {
      return this.quiz.questions.find(
        (x) => x.questionId == this.quiz.activeQuestion.questionId
      );
    },
    category() {
      return this.quiz.categories.find(
        (x) => x.categoryId == this.question.categoryId
      );
    },
  },
};
</script>

<style scoped lang="scss">
.quiz-header {
  font-size: 1.7em;
  font-weight: 600;
  line-height: 2em;
}
.quiz-title {
  font-size: 2em;
  font-weight: 600;
  line-height: 2em;
}

</style>
