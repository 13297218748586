<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Quizname</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Name"
                v-model="name"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">abbrechen</v-btn>
        <v-btn color="primary" type="submit" @click="add" :disabled="!valid">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditName",
  components: {},
  data() {
    return {
      dialog: false,
      name: "",
    };
  },

  methods: {
    open(name) {
      this.dialog = true;
      this.name = name;
    },
    close() {
      this.name = "";
      this.dialog = false;
    },
    add() {
      this.$emit("didUpdateQuizName", this.name);
      this.close();
    },
  },

  computed: {
    valid() {
      return this.name;
    },
  },
};
</script>

<style scoped>
.avatar-selection {
  margin: 10px;
}
</style>
