<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Neues Team</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Name"
                v-model="name"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-wrap">
                <v-label>Welche Farbe?</v-label>
                <v-radio-group v-model="selectedColor" row>
                  <v-radio
                    v-for="color in $allColors()"
                    :key="color"
                    :value="color"
                    :color="color"
                    class="mr-3 mb-3"
                  >
                    <template v-slot:label>
                      <v-chip small :color="color"></v-chip>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="add" :disabled="!valid">
          Team erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddTeam",
  components: {},
  data() {
    return {
      dialog: false,
      name: "",
      selectedColor: undefined,
      colors: this.$allColors(),
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.selectedColor = this.colors[
        Math.floor(Math.random() * this.colors.length)
      ];
    },
    close() {
      this.name = "";
      this.dialog = false;
    },
    add() {
      this.$emit("didAddTeam", {
        teamId: Math.random()
          .toString(36)
          .substr(2, 6),
        name: this.name,
        color: this.selectedColor,
      });
      this.close();
    },
  },

  computed: {
    valid() {
      return this.name && this.selectedColor;
    },
  },
};
</script>

<style scoped>
.avatar-selection {
  margin: 10px;
}
</style>
