<template>
  <v-row v-if="quiz">
    <v-col cols="12">
      <h1 class="d-inline">
        Spieler / Teams
      </h1>
      <v-btn class="ml-10" small @click="$refs.addTeam.open()" primary
        ><v-icon>mdi-plus</v-icon> Team hinzufügen</v-btn
      >
    </v-col>
    <v-col cols="12" class="d-flex flex-wrap">
      <template v-for="team in quiz.teams">
        <TeamList
          :key="team.name"
          v-bind:team="team"
          v-bind:quiz="quiz"
          :showPlayers="true"
        >
          <template v-slot:teamAction="{ team }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="chooseRandomPlayer(team)">
                  <v-list-item-title>Spieler auswählen</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="removeTeam(team)">
                  <v-list-item-title>Team löschen</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:playerAction="{ player }">
            <v-list-item-action @click="removePlayer(player)">
              <v-btn icon>
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
        </TeamList>
      </template>
    </v-col>
    <AddTeam ref="addTeam" v-on:didAddTeam="addTeam" />
  </v-row>
</template>
<script>
import firebase from "../Firebase";
import TeamList from "../components/TeamList";
import AddTeam from "../components/AddTeam";
export default {
  name: "AdminPlayersList",
  components: { TeamList, AddTeam },
  props: ["quiz"],
  data() {
    return {
      ref: firebase.firestore(),
    };
  },
  methods: {
    updateGame() {
      this.ref
        .collection("quizes/")
        .doc(this.quiz.id)
        .set(this.quiz);
    },

    removeTeam(team) {
      if (
        confirm(`Willst du wirklich das gesamte Team ${team.name} löschen?`)
      ) {
        this.quiz.teams = this.quiz.teams.filter(
          (x) => x.teamId != team.teamId
        );
        this.quiz.players = this.quiz.players.filter(
          (x) => x.teamId != team.teamId
        );
        this.updateGame();
      }
    },

    chooseRandomPlayer(team) {
      var teamPlayers = this.quiz.players.filter(
        (x) => x.teamId == team.teamId
      );
      this.quiz.randomPlayer =
        teamPlayers[Math.floor(Math.random() * teamPlayers.length)];
      this.updateGame();
    },

    addTeam(team) {
      this.quiz.teams.push(team);
      this.updateGame();
    },

    removePlayer(player) {
      if (confirm(`Willst du wirklich den Spieler ${player.name} löschen?`)) {
        this.quiz.players = this.quiz.players.filter(
          (x) => x.playerId != player.playerId
        );
        this.updateGame();
      }
    },
  },
};
</script>
