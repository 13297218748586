<template>
  <div>
    <v-container v-if="!isAdmin">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <img
            :src="require(`@/assets/images/rakete-ahoi.svg`)"
            class="logo mb-5"
          />
          <h1 class="display-1 mb-4 text-center">
            Ney ney ney großer - so nicht!
          </h1>
          <p>
            Du darfst natürlich nur ein Spiel administrieren, das du auch
            erstellt hast. Falls du dieses Spiel erstellt haben solltest musst
            die Seite auf dem gleichen Gerät öffnen auf dem du es damals
            erstellt hast.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-5" v-if="quiz != undefined && isAdmin">
      <v-row>
        <v-col cols="12">
          <h1 class="display-1 mb-4 text-center">
            Rakete Ahoi Quiz –
            <template v-if="quiz.name.trim().length == 0">
              Unbenanntes Quiz
            </template>
            <template v-else>
              {{ quiz.name }}
            </template>
            <v-btn icon @click="$refs.editName.open(quiz.name)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </h1>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" align="center" justify="center">
          <v-switch
            v-model="quiz.showBoard"
            label="Fragen Board anzeigen"
            @change="() => this.updateGame()"
          ></v-switch>
        </v-col>
        <v-col cols="3">
          <v-btn small class="mt-3" @click="resetAnswers"
            >Antworten zurücksetzen</v-btn
          >
        </v-col>
      </v-row>
      <AdminPlayersList v-bind:quiz="quiz" />
      <AdminCategoryList v-bind:quiz="quiz" />
      <ActiveQuestion v-bind:quiz="quiz" />
      <EditName ref="editName" v-on:didUpdateQuizName="updateQuizName" />
      <AdminRandomPlayer
        v-bind:quiz="quiz"
        v-on:didCloseRandomPlayer="updateGame"
      />
    </v-container>
  </div>
</template>

<script>
import firebase from "../Firebase";
const quizes = firebase.firestore().collection("quizes");
import AdminPlayersList from "../components/AdminPlayersList";
import AdminCategoryList from "../components/AdminCategoryList";
import EditName from "../components/EditName";
import ActiveQuestion from "../components/ActiveQuestion";
import AdminRandomPlayer from "../components/AdminRandomPlayer";

export default {
  name: "Admin",
  components: {
    AdminPlayersList,
    AdminCategoryList,
    EditName,
    ActiveQuestion,
    AdminRandomPlayer,
  },
  data() {
    return {
      quizId: this.$route.params.gameId,
      ref: firebase.firestore(),
      quiz: undefined,
    };
  },
  methods: {
    updateGame() {
      this.ref
        .collection("quizes/")
        .doc(this.quizId)
        .set(this.quiz);
    },

    updateQuizName(name) {
      this.quiz.name = name;
      this.updateGame();
    },

    resetAnswers() {
      if (confirm("Alle Fragen zurücksetzen?")) {
        delete this.quiz.randomPlayer;
        this.quiz.questions.forEach((x) => {
          delete x.winnerTeamId;
          x.answers = [];
        });

        this.updateGame();
      }
    },
  },

  computed: {
    isAdmin() {
      var createdGame =
        localStorage[`rakete-ahoi-quizes-created-${this.quizId}`];
      return createdGame == "true";
    },
  },

  watch: {
    quizId: {
      immediate: true,
      handler(id) {
        if (this.isAdmin) {
          this.$bind("quiz", quizes.doc(id));
        }
      },
    },
  },
};
</script>

<style lang="scss"></style>
