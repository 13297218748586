<template>
  <v-dialog v-model="dialog" persistent max-width="600px" v-if="question">
    <v-card>
      <v-card-title>
        <span class="headline">Frage bearbeiten</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea autofocus label="Frage" v-model="title" required />
            </v-col>
            <v-col cols="12">
              <v-slider
                v-model="points"
                :label="'Punkte: ' + points"
                min="1"
                max="100"
              ></v-slider>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-wrap" v-if="teams.length > 0">
                <v-select
                  :items="adjustedTeams"
                  label="Gewinnerteam"
                  item-text="name"
                  item-value="teamId"
                  v-model="winnerTeamId"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-wrap">
                <v-select
                  :items="questionTypes"
                  label="Fragen Typ"
                  item-text="name"
                  item-value="id"
                  v-model="questionType"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="questionType == 'default'">
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Antwort"
                v-model="meta.answer"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="questionType == 'geo'">
            <v-col cols="6">
              <v-text-field
                autofocus
                label="Lat"
                v-model="meta.lat"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                autofocus
                label="Long"
                v-model="meta.lng"
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row v-if="questionType == 'guess'">
            <v-col cols="6">
              <v-text-field
                type="number"
                autofocus
                label="Antwort"
                validate-on-blur
                v-model.number="meta.answer"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close">abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="add" :disabled="!valid">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditQuestion",
  components: {},
  props: ["teams"],
  data() {
    return {
      dialog: false,
      question: undefined,
      title: "",
      points: 1,
      questionType: "default",
      winnerTeamId: "UNANSWERED",
      meta: {},
      questionTypes: [
        { id: "default", name: "Nur Frage" },
        {
          id: "geo",
          name: "Geo Guesser",
        },
        {
          id: 'guess',
          name: "Schätzfrage"
        }
      ],
    };
  },

  methods: {
    open(question) {
      this.question = question;
      this.title = question.title;
      this.points = question.points;
      this.meta = question.meta;
      this.questionType = question.questionType || "default";
      this.winnerTeamId =
        "winnerTeamId" in question ? question.winnerTeamId : "UNANSWERED";
      this.dialog = true;
    },
    close() {
      this.question = undefined;
      this.winnerTeamId = undefined;
      this.dialog = false;
    },
    add() {
      this.question.title = this.title;
      this.question.points = this.points;
      this.question.meta = this.meta;
      this.question.questionType = this.questionType || "default";

      if (this.winnerTeamId === "UNANSWERED") {
        delete this.question.winnerTeamId;
      } else {
        this.question.winnerTeamId = this.winnerTeamId;
      }
      this.$emit("didUpdateQuestion", this.question);
      this.close();
    },
  },

  computed: {
    adjustedTeams() {
      const emptyOption = [
        {
          teamId: "UNANSWERED",
          name: "Nicht beantwortet",
        },
        {
          teamId: null,
          name: "Falsch beantwortet",
        },
      ];
      return emptyOption.concat(this.teams);
    },
    valid() {
      return this.title && this.points && (
        (this.questionType == 'default') || 
        (this.questionType == 'geo' && this.meta.lat && this.meta.lng) ||
        (this.questionType == 'guess' && this.meta.answer)
      );
    },
  },
};
</script>

<style scoped>
.avatar-selection {
  margin: 10px;
}
</style>
