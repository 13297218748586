var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.quiz)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"d-inline"},[_vm._v(" Spieler / Teams ")]),_c('v-btn',{staticClass:"ml-10",attrs:{"small":"","primary":""},on:{"click":function($event){return _vm.$refs.addTeam.open()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Team hinzufügen")],1)],1),_c('v-col',{staticClass:"d-flex flex-wrap",attrs:{"cols":"12"}},[_vm._l((_vm.quiz.teams),function(team){return [_c('TeamList',{key:team.name,attrs:{"team":team,"quiz":_vm.quiz,"showPlayers":true},scopedSlots:_vm._u([{key:"teamAction",fn:function(ref){
var team = ref.team;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.chooseRandomPlayer(team)}}},[_c('v-list-item-title',[_vm._v("Spieler auswählen")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.removeTeam(team)}}},[_c('v-list-item-title',[_vm._v("Team löschen")])],1)],1)],1)]}},{key:"playerAction",fn:function(ref){
var player = ref.player;
return [_c('v-list-item-action',{on:{"click":function($event){return _vm.removePlayer(player)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})]})],2),_c('AddTeam',{ref:"addTeam",on:{"didAddTeam":_vm.addTeam}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }