<template>
  <v-row v-if="quiz">
    <v-col cols="12">
      <h1 class="d-inline">
        Kategorien
      </h1>
      <v-btn class="ml-10" small @click="$refs.addCategory.open()" primary
        ><v-icon>mdi-plus</v-icon> Kategorie hinzufügen</v-btn
      >
    </v-col>
    <v-col cols="12" class="d-flex flex-wrap">
      <template v-for="category in quiz.categories">
        <v-card
          :key="category.name"
          class="mr-5 mb-5"
          min-width="300"
          max-width="400"
        >
          <v-toolbar
            :color="category.color"
            :dark="$isDarkColor(category.color)"
          >
            <v-toolbar-title>
              {{ category.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$refs.addCategory.open(category)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn icon @click="removeCategory(category)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list>
            <v-list-item
              v-for="question in questionsForCategory(category)"
              :key="question.questionId"
            >
              <v-list-item-avatar>
                <v-icon
                  :color="question.winnerTeamId !== null ? 'green' : 'grey'"
                  v-if="'winnerTeamId' in question"
                  >mdi-checkbox-marked</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="display-title">
                  {{ question.points }}
                  <template v-if="question.questionType == 'geo'">
                    <v-icon class="text--green">mdi-earth</v-icon>
                  </template>
                  <template v-if="question.questionType == 'guess'">
                    <v-icon class="text--green">mdi-swap-vertical-circle-outline</v-icon>
                  </template>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="removeQuestion(question)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon @click="$refs.editQuestion.open(question)">
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon @click="showQuestion(question)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="questionsForCategory(category).length == 0"
              class="text-center"
            >
              Keine Fragen vorhanden...
            </v-list-item>
            <v-list-item @click="addQuestion(category)">
              <v-icon>mdi-plus</v-icon> Frage hinzufügen
            </v-list-item>
          </v-list>
        </v-card>
      </template>
    </v-col>
    <AddCategory ref="addCategory" v-on:didUpdateCategory="updateCategory" />
    <EditQuestion
      ref="editQuestion"
      v-on:didUpdateQuestion="updateQuestion"
      v-bind:teams="quiz.teams"
    />
  </v-row>
</template>
<script>
import firebase from "../Firebase";
import AddCategory from "../components/AddCategory";
import EditQuestion from "../components/EditQuestion";

export default {
  name: "AdminCategoryList",
  components: { AddCategory, EditQuestion },
  props: ["quiz"],
  data() {
    return {
      ref: firebase.firestore(),
    };
  },
  methods: {
    updateGame() {
      this.ref
        .collection("quizes/")
        .doc(this.quiz.id)
        .set(this.quiz);
    },

    addQuestion(category) {
      var question = {
        questionId: null,
        title: "",
        points: 1,
        questionType: "default",
        answers: [],
        meta: {},
        categoryId: category.categoryId,
      };
      this.$refs.editQuestion.open(question);
    },

    removeQuestion(question) {
      if (
        confirm(
          `Willst du wirklich die gesamte Frage mit ${question.points} Punkten löschen?`
        )
      ) {
        this.quiz.questions = this.quiz.questions.filter(
          (x) => x.questionId != question.questionId
        );
        this.updateGame();
      }
    },

    updateQuestion(question) {
      if (!question.questionId) {
        question.questionId = Math.random()
          .toString(36)
          .substr(2, 6);
        this.quiz.questions.push(question);
      }
      this.updateGame();
    },

    removeCategory(category) {
      if (
        confirm(
          `Willst du wirklich die gesamte Kategorie ${category.name} löschen?`
        )
      ) {
        this.quiz.categories = this.quiz.categories.filter(
          (x) => x.categoryId != category.categoryId
        );
        this.updateGame();
      }
    },

    showQuestion(question) {
      this.quiz.activeQuestion = {
        questionId: question.questionId,
        showText: false,
        showAnswers: false,
        allowAnswers: false,
        showWinningTeam: false,
      };
      this.updateGame();
    },

    updateCategory(category) {
      if (!this.quiz.categories) {
        this.quiz.categories = [];
      }
      this.quiz.categories = this.quiz.categories.filter(
        (x) => x.categoryId != category.categoryId
      );
      this.quiz.categories.push(category);
      this.updateGame();
    },
  },

  computed: {
    questionsForCategory() {
      return (category) =>
        this.quiz.questions
          .filter((x) => x.categoryId == category.categoryId)
          .sort((x, y) => y.points - x.points);
    },
  },
};
</script>
