<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="!categoryId">Neue Kategorie</span>
        <span class="headline" v-else>Kategorie bearbeiten</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Name"
                v-model="name"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-label>Welche Farbe?</v-label>
              <v-radio-group v-model="selectedColor" row>
                <v-radio
                  v-for="color in $allColors()"
                  :key="color"
                  :value="color"
                  :color="color"
                  class="mr-3 mb-3"
                >
                  <template v-slot:label>
                    <v-chip small :color="color"></v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close">abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="add" :disabled="!valid">
          <template v-if="!categoryId">Kategorie erstellen</template>
          <template v-else>Kategorie speichern</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddCategory",
  components: {},
  data() {
    return {
      dialog: false,
      categoryId: undefined,
      name: "",
      selectedColor: undefined,
      colors: this.$allColors(),
    };
  },

  methods: {
    open(category) {
      if (category) {
        this.categoryId = category.categoryId;
        this.name = category.name;
        this.selectedColor = category.color;
      } else {
        this.categoryId = undefined;
        this.name = "";
        this.selectedColor = this.colors[
          Math.floor(Math.random() * this.colors.length)
        ];
      }
      this.dialog = true;
    },
    close() {
      this.categoryId = undefined;
      this.name = "";
      this.dialog = false;
    },
    add() {
      this.$emit("didUpdateCategory", {
        categoryId:
          this.categoryId ||
          Math.random()
            .toString(36)
            .substr(2, 6),
        name: this.name,
        color: this.selectedColor,
        questions: [],
      });
      this.close();
    },
  },

  computed: {
    valid() {
      return this.name && this.selectedColor;
    },
  },
};
</script>

<style scoped>
.avatar-selection {
  margin: 10px;
}
</style>
