<template>
  <div>
    <v-row>
      <v-col><h2>Antworten:</h2></v-col>
    </v-row>
    <v-row>
      <template large v-for="team in quiz.teams">
        <v-col cols="4" :key="team.teamId">
          <h3>
            {{ team.name }} :
            <strong>{{ averageValueForTeam(team) }} ( Δ {{Math.abs(question.meta.answer - averageValueForTeam(team))}})</strong>
          </h3>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense>
          <v-subheader>Einzelne Antworten</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="answer in sortedAnswers"
              :key="answer.playerId"
              class="detail-answers"
            >
              <v-list-item-title
                style="font-size: 1em;"
                class="pb-2"
                >{{
                  playerWithId(answer.playerId).name
                }}</v-list-item-title
              >

              <v-list-item-icon>
                {{ Math.round(answer.value.value * 100) / 100 }}
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "GuessQuestionResults",
  props: ['question', 'quiz'],
  methods: {
    playerWithId(playerId) {
      return this.quiz.players.find((x) => x.playerId == playerId);
    },
    teamWithId(teamId) {
      return this.quiz.teams.find((x) => x.teamId == teamId);
    },
  },
  computed: {
    sortedAnswers() {
      var answers = JSON.parse(JSON.stringify(this.question.answers));
      return answers.sort((x, y) => x.value.value - y.value.value);
    },
    averageValueForTeam() {
      return (team) => {
        var players = this.quiz.players
          .filter((x) => x.teamId == team.teamId)
          .map((x) => x.playerId);
        var answers = this.question.answers.filter((x) =>
          players.includes(x.playerId)
        );
        var sum = (x, y) => x + y;
        return (
          Math.round(
            (answers.map((x) => x.value.value).reduce(sum, 0) /
              answers.length) *
              100
          ) / 100
        );
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-answers {
  font-size: 2em;
}
</style>