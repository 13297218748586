<template>
  <v-dialog v-model="isActive" persistent max-width="600px">
    <v-card v-if="this.quiz.randomPlayer != undefined">
      <v-card-title>
        <span class="headline">Zufälliger Spieler</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-row justify-center align-center"
            >
              <Avatar :avatar="this.quiz.randomPlayer.avatar" class="mr-5" />
              <h1>{{ this.quiz.randomPlayer.name }}</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="close">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Avatar from "../components/Avatar";

export default {
  name: "AddPlayer",
  components: { Avatar },
  props: ["quiz"],
  data() {
    return {};
  },

  methods: {
    close() {
      delete this.quiz.randomPlayer;
      this.dialog = false;
      this.$emit("didCloseRandomPlayer");
    },
  },

  computed: {
    isActive() {
      return this.quiz && "randomPlayer" in this.quiz;
    },
  },
};
</script>
